@import url('https://fonts.googleapis.com/css?family=Lato:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Manrope', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'lato';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F5F7F9;
}
.radiant-progress-bar {
  background: radial-gradient(circle at center,
      rgb(30, 1, 186), 
      transparent 70% 
    );
}
/* button{
  font-family: -apple-system, BlinkMacSystemFont,'Manrope', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
} */
/* code {
  font-family: source-code-pro, 'lato';
} */

svg {
  vertical-align: text-top;
}